.submission-container {}

.submission-container>button {
    width: 300px;
}

.s-categories {
    margin-top: 30px;
    display: flex;
}

.s-category {
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    border-radius: 10px;
    flex: 1;
    margin: 10px;
    min-width: 300px;
    /* padding: 20px; */
    cursor: pointer;
    transition: 0.5s;
    border: 2px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.s-category:hover {
    background-color: #dedede;
}

.s-category-selected {
    background-color: #d3e3fd;
    border: 2px solid #e10d1c;
}

.s-category-inner>img {
    height: 100px;
}

.s-category-inner>h3 {
    margin-bottom: -5px;
}

.s-category-inner>p {
    font-size: 12px;
}

.booking-success>h3 {
    margin-bottom: 0px;
}

.booking-success>p {
    font-size: 14px;
}

.s-category-inner {
    padding: 20px;
}

.c-category-bottom {
    background-color: #e10d1c;
    padding: 5px 20px;
    font-size: 12px;
    color: #fff;
    font-family: Roboto;
    letter-spacing: 0.5px;
    font-weight: 600;
    width: 88%;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: -2px;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .s-categories {
        flex-wrap: wrap;
        margin-top: -10px;
    }

    .s-category {
        /* height: 10px; */
        margin-bottom: 30px;
    }

    .s-category-inner>img {
        height: 60px;
    }

    .s-category-inner {
        padding: 10px;
    }

    .s-category-inner>h3 {
        margin-top: 3px;
    }

    .category-button>button {
        margin-top: -10px;
    }


    .c-category-bottom {
        width: 89.5%;
    }
}