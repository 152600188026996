.home-banner {
    height: 450px;
    padding: 15px;
    /* width: 100%; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: #18428c; */

    background: rgb(0, 22, 66);
    background: linear-gradient(90deg, rgba(0, 22, 66, 1) 43%, rgba(24, 66, 140, 1) 100%);
    display: flex;
}

.home-banner-heading-section {
    flex: 1;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-banner-heading-section>.btn {
    width: 97px;
    letter-spacing: 1px;
}

.home-banner-mockup-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
}

.home-banner-mockup-section>img {
    height: 450px;
}

.title {
    font-family: 'Fira Sans';
    /* letter-spacing: 1.3px; */
    font-weight: 900;
    font-size: 45px;
    color: #1d355c;
}

.title-padding {
    margin-top: -10px;
}

.home-banner-title {
    font-family: 'Fira Sans';
    letter-spacing: 1.3px;
    font-weight: 900;
    font-size: 50px;
    color: #fff;
    /* margin-bottom: 20px; */
}

.home-banner-caption {
    margin-top: 10px;
    margin-bottom: 30px;
    color: #dadada;
    font-size: 18px;
    font-weight: 600;
}

.home-banner-mode {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 14px;
    font-family: HelveticaNeue, arial;
    font-weight: bold;
    cursor: pointer;
}

.hrr-container {}

.hrr-content {
    margin-top: 20px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    /* background-color: red; */
}

.hrr-content>.adjuster-item {
    width: 44.8%;
    margin: 1%;
    cursor: pointer;
}

.sub-heading {
    font-size: 20px;
    font-weight: 500;
}

.home-section2-padding {
    padding: 60px 30px;
}

.home-features {
    display: flex;
}

.home-feature {
    flex: 1;
    background-color: #fff;
    border-radius: 20px;
    margin: 20px;
    padding: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.home-feature-icon {
    width: 70px;
    margin-bottom: 25px;
}

.home-feature-title {
    font-family: 'Fira Sans';
    font-size: 25px;
}

.home-feature-caption {
    font-size: 13px;
}

.home-careers {
    text-align: center;
}

.home-careers-caption {
    margin-top: -15px;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {

    .home-banner {
        flex-direction: column;
        height: 100vh;
        padding: 0px;
        /* width: 100%; */
    }

    .home-features {
        flex-direction: column;
    }

    .home-banner-title {
        font-size: 35px;
    }

    .hrr-content>.adjuster-item {
        width: 100%;
        margin: 10px 0%;
    }

    .home-banner-mockup-section {
        padding-right: 0px;
    }

    .home-banner-mockup-section>img {
        height: 400px;
        /* margin-right: -20px; */
        margin-top: -70px;
    }
    .title {
        font-size: 30px;
    }
}