.circle {
  background-color: #EDEDED;
  border-radius: 100%;
  width: 80px;
  height: 80px;
}

.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto
}

.center2 {
  position: absolute;
  top: 32%;
  left: 35%;
}

.add-icon {
  font-size: 30px;
  color: #D5D5D5
}

.top-row {
  display: flex;
}

.select-outer {
  margin-top: 20px;
  border: 1px solid #EDEDED;
  border-radius: 5%;
  /* margin: 5px; */
  cursor: pointer;
  /* max-height: 180px; */
  min-height: 30vh;
  height: 95%;
}

.row-non-primary {
  /* margin-top: -12px; */
  margin-left: 12%;
  background-color: white;
  width: 80%;
}

.select-outer-primary {
  border: 1px solid #EDEDED;
  background-color: #007ABB;
  border-radius: 5%;
  margin: 5px;
  cursor: pointer;
}

.primary-icon {
  font-size: 10px;
}

.primary-color {
  font-weight: 600;
  color: #007ABB;
}

.image-card {
  margin-top: -10px;
}

.noPadding {
  padding: 2px;
}