.marketplace-banner {
    height: 300px;
    padding: 15px;
    /* width: 100%; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* background-color: #18428c; */

    background: rgb(0, 22, 66);
    background: linear-gradient(90deg, rgba(0, 22, 66, 1) 43%, rgba(24, 66, 140, 1) 100%);
    display: flex;
}


.mp-banner-title {
    font-family: 'Fira Sans';
    letter-spacing: 1.3px;
    font-weight: 900;
    font-size: 45px;
    color: #fff;
    height: 10px;
    /* margin-bottom: 20px; */
}

.mp-search-area {}

.mp-search-dropdown {
    background-color: #fff;
    /* padding: 0px 10px 10px 10px !important; */
    border-radius: 30px;
    height: 45px;
    width: 200px;
    padding-bottom: 10px !important;
    border: 0px transparent !important;
}

.mp-search-button {
    width: 150px;
    height: 55px;
    margin-top: 3px;
}

.mp-search-dropdown>.MuiInputLabel-shrink {
    color: #fff !important;
    margin-top: -10px !important;
}

.mp-search-dropdown>.MuiInputBase-root>fieldset {
    border: 0px;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .marketplace-banner {
        height: 400px;
    }

    .mp-banner-title {
        font-size: 36px;
    }

    .mp-search-area {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px !important;

    }

    .mp-search-dropdown {
        margin-left: 0px !important;
        margin-top: 15px !important;
        width: 200%;
    }

    .mp-search-button {
        margin-top: 15px !important;
        margin-left: 0px !important;

        width: 200%;
    }

    .marketplace-banner>p {
        font-size: 12px;
        margin: 10px 40px;
    }
}