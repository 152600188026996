.footer {
    background-color: #000;
    color: #FFF;
    padding: 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-copy {
    font-size: 14px;
}

.footer-social-icons {
    display: flex;
    flex-direction: row;
}

.footer-social-icons>a {
    margin: 0px 5px;
    font-size: 20px;
    color: #FFF;
}

.footer-logo {
    margin-left: 5px;
    height: 25px;
}

.footer-links {
}

.footer-links>a{
    color: #FFF;
    font-size: 13px;
    font-weight: 800;
}

.footer-links>a{
    margin-right: 6px;
}

.footer-links>span{
    margin-right: 6px;
}