.auth-container {
    display: flex;
}

.auth-sidebar {
    flex: 0.3;
    padding: 20px;
}

.auth-sidebar-container {
    display: flex;
    flex-direction: column;

    border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 2px -1px 10px 2px;

    background-color: #000;

}

.auth-sidebar-item {
    padding: 15px 20px;
    color: #FFF;
    font-weight: bold;
}

.auth-sidebar-item.active{
    color: #4d89ff;
}

.auth-sidebar-item>i {
    margin-right: 10px;
}

.auth-content {
    flex: 1;
    padding: 20px 40px;
}

/* mobile */
@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .auth-container {
        display: block;
    }
}