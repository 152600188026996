.booking-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
}

.booking-card {
    width: 31%;
    /* height: 100px; */
    margin: 20px 10px;
    position: relative;
}

.booking-map {
    /* background-color: red; */
    height: 200px;
    width: 100%;
    z-index: 1;
    border-radius: 10px;
}

.booking-map-inner {
    height: 30px;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 130px;
    z-index: 2;
    border-radius: 20px;
    background-color: #00667d;
    color: #fff;
    font-weight: 800;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.booking-distance {
    font-size: 11px;
    width: 100%;
    height: 100%;
    /* padding-left: 10px; */
}

.booking-user-container {
    padding: 10px 0px;
    justify-content: space-between;
}

.booking-basic-data {
    align-items: center;
}

.booking-basic-data-inner {
    text-align: left;
    justify-content: start;
}

.booking-user-image {
    height: 25px;
    width: 25px;
    border-radius: 100px;
    background-size: cover;
}

.booking-username {
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
}

.booking-rate {
    font-family: Macan, HelveticaNeue, arial;
    font-size: 16px;
    font-weight: 600;
    margin-right: 5px;
    letter-spacing: 0.5px;
}

.booking-category {
    font-size: 14px;
    margin-top: 6px;
}

.btn-accept {
    height: 15px;
    padding: 6px 4px !important;
    width: 60px;
    margin-top: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.booking-rate-area {
    display: flex;
    align-items: flex-end;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .booking-card {
        width: 100%;
    }
}