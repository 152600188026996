.booking-main-container {
    display: flex;
    /* width: 100%; */

}

.booking-left-area {
    flex: 2;
    position: relative;
}

.booking-page-map {
    height: 400px;
    border-radius: 20px;
}

.booking-right-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 35px;

    /* background-color: red; */
}


.booking-direction {
    margin-top: -10px;
}

.booking-direction>i {
    font-size: 12px;
}

.booking-direction-address {
    font-size: 13px;
    margin-left: 5px;
    color: #565656;
}

.booking-user-container2 {
    margin-bottom: 20px;
    float: right;
    margin-right: 10px;
    position: relative;
}

.booking-user-container2 {
    /* position: absolute; */
    /* right: -10px; */
    /* top: 325px; */
    /* background-color: #dbe9f6; */
    /* padding: 10px 15px; */
    /* border-radius: 10px 0px; */
}

.booking-user-image.image2 {
    height: 35px;
    width: 35px;
}

.booking-user-data {
    margin-left: 10px;
}

.booking-datatime {
    font-size: 12px;
}

.booking-p-category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: beige;
    /* width: 80%; */
    border-radius: 10px;
    padding: 5px 15px;
    margin-top: -3px;
}

.booking-p-category-image {
    height: 50px;
}

.booking-p-category-title {
    font-size: 14px;
    font-weight: 700;
    margin-left: 10px;
    color: #565656;
}


.booking-right-area>button {
    width: 100%;
    border-radius: 5px;
}

.booking-p-terms {
    margin-top: 0px;
    font-size: 12px;
    text-align: center;
}

.booking-datatime.secondary-text {
    text-decoration: underline;
}

.booking-status-container {
    background-color: rgb(38, 163, 36);
    padding: 0px 15px;
    font-size: 12px;
    color: #FFF;
    border-radius: 20px 20px 0px 0px;
    font-weight: 800;
    height: 35px;
    margin-bottom: -15px !important;
    margin-right: -2px !important;
}

.booking-right-area>hr {
    width: 100%;
    margin-top: -11px;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .booking-main-container {
        flex-direction: column;
        padding: 25px;
    }

    .booking-right-area{
        /* background-color: red; */
        padding: 0px;
        margin-top: 20px;
    }
}