.mapWrapper {
    position: relative;
    display: block;
    height: 350px;
    width: 100%;
}

.address-input-picker{
    margin-bottom: 0px !important;
}
.gfRow {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.gfItem {
    flex: 1;
    /* width: 47%; */
    padding: 10px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2196F3;
    background-color: white;
    border: 0.2px solid #2196F3;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
}

.gfActive {
    color: white;
    background-color: #2196F3;
    border: 0.2px solid white;
}

.gfItem>i {
    margin-right: 5px;
}

.gfItem>span {
    font-size: 16px;
}