.password-field-container {
    display: flex;
    width: 100%;

}

.password-field {
    flex: 1;
}

.password-icon {
    flex: 0.15;
    color: #2e3c4c;
    /* padding: 10px; */
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-form-field {
    flex: 1;
}

.password-form-container {
    display: flex;
    width: 100%;
}