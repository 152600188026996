.profile-img {
    border-radius: 100px;
    height: 70px;
}

.name-text{
    font-size: 14px;
}


.form-container {
    width: 30vw;
}

.form-agreement {
    font-size: 10px;
    text-align: center;
}

.form-sub-heading {
    margin-top: -5px;
    font-size: 14px;
}

.profile-submit-btn{
    width: 300px;
}

/* huge monitor */
@media (min-width:1920px) { 
    .form-container {
        width: 20vw;
    }
}

/* mobile */
@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .form-container {
        width: 80% !important;
    }
}